import hostesses from '../constants/HostessesVisioArray';
import { Helmet } from "react-helmet-async";
import { NavLink } from 'react-router-dom';
import useScreenSize from '../hooks/useScreenSize';
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import visioBelgique from '../img/visio-Belgique.webp';

function Visio() {

  const { width } = useScreenSize();

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href="https://telrose-telephone.be/img/banner-visio.webp" />
        <title>Téléphone Rose en Visio | Rencontres Intimes en Direct au 090404500</title>
        <meta name='description' content='Découvrez le plaisir des rencontres intimes en direct avec notre service de téléphone rose en visio. Des échanges coquins et des moments de complicité vous attendent 24/7. Rejoignez-nous maintenant !' />
      </Helmet>
      <div style={{ background: "linear-gradient(180deg, rgba(0, 0, 0) 0%, rgba(255, 0, 0, 0.055) 50%, rgba(0, 0, 0) 100%), url(/img/banner-visio.webp)" }} className="header-visio text-center d-flex flex-column justify-content-center">
        <Container fluid="lg" className="header-home-container text-white">
          <h1 className="fw-bold display-5 mb-4">Appels en visio</h1>
          <div className='d-flex align-items-center justify-content-center'>
            <FontAwesomeIcon icon={faVideo} className='fs-2 p-2 me-3 icon-video' />
            {width < 769
              ?
              <a href="tel:090404500" aria-label="Appeler en visio Belgique"><Image width={251} height={67} src={visioBelgique} className="sva-header" rounded alt='numéro appel visio Belgique : 090404500' fluid /></a>
              :
              <Image width={251} height={67} src={visioBelgique} className="sva-header" rounded alt='numéro appel visio Belgique : 090404500' fluid />
            }
          </div>
        </Container>
      </div>
      <Container fluid="lg" className="container-hostesses mt-3 mt-lg-5 text-center">
        <h2 className="title-red-hostesses fs-3 py-3 py-md-4 mb-5 fw-bold">Découvrez notre nouveau service d'appel en visio avec nos hôtesses</h2>
        <p>Notre service de téléphone rose évolue pour vous offrir une expérience encore plus immersive et personnelle grâce aux appels en visio avec nos hôtesses. Que vous soyez à la recherche d'une conversation sensuelle, d'un moment de détente ou d'une connexion plus profonde, nos hôtesses sont là pour répondre à vos désirs.</p>
        <p>Profitez d'une interaction en face à face pour une expérience plus réaliste et engageante. Nos hôtesses vous offrent toute leur attention et créent un environnement où vous vous sentirez écouté et compris.</p>
        <p className="fw-bold">Parcourez les profils de nos hôtesses et sélectionnez celle qui correspond le mieux à vos envies du moment.</p>
        <Row as={"ul"} xs={2} sm={3} lg={4} xxl={5} className="mt-5">
          {hostesses.map(hostesse => (
            <Col key={hostesse.id} as={"li"} className="mb-3">
              <NavLink className={"text-decoration-none"} to={`/profil-hotesse-visio/${hostesse.id}/${hostesse.name}`}>
                <Card className="h-100 rounded-3 overflow-hidden" text="white">
                  <Card.Img width={240} height={240} className="rounded-top-3" variant="top" src={`/img/photos_visio/photos_small/${hostesse.photo}.webp`} alt={hostesse.alt} />
                  <Card.ImgOverlay className='p-0 text-start'>
                    <Card.Title as={"span"}><FontAwesomeIcon icon={faVideo} className='p-2 fs-5 card-icon rounded-bottom-1 rounded-start-0' /></Card.Title>
                  </Card.ImgOverlay>
                  <Card.Body>
                    <Card.Title className="mb-3 p-1 fw-bold card-title-custom">{hostesse.name}</Card.Title>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default Visio