import { Container, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useScreenSize from '../hooks/useScreenSize';
import useFilterByIdParam from "../hooks/useFilterByIdParam";
import hostessesVisioArray from "../constants/HostessesVisioArray";
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import visioBelgique from '../img/visio-Belgique.webp';
import visioSuisse from '../img/visio-Suisse.webp';

function Profil() {

  const hostesseProfil = useFilterByIdParam(hostessesVisioArray);

  const { width } = useScreenSize();

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("iframe-container").style.height = "825px";
      document.getElementById("iframe-chat").style.position = "static";
      document.getElementById("iframe-chat").style.opacity = "100";
      document.getElementById("button-loading").style.display = "none";
      document.getElementById("title-chat-container").style.display = "block";
    }, 4000);
  }, [])

  if (hostesseProfil) {
    return (
      <>
        <Helmet>
          {hostesseProfil.map(hostesse => (
            <Fragment key={hostesse.id}>
              <title key={hostesse.id}>{hostesse.metaTitle}</title>
              <meta name="description"
                content={hostesse.metaDescription} />
              <link rel="canonical" href={`https://telrose-telephone.be/profil-hotesse/${hostesse.id}/${hostesse.name}`} />
              <link rel="preload" as="image" href={`https://telrose-telephone.be/img/photos_large/${hostesse.photo}.webp`} />
            </Fragment>
          ))}
        </Helmet>
        <Container fluid="xl" className="my-3">
          <Row className="m-0 card-profil overflow-hidden rounded-3">
            {hostesseProfil.map(hostesse => (
              <Fragment key={hostesse.id}>
                <Col xs={12} lg={6} className="text-center p-0">
                  <Image width={650} height={650} className="photo-profil" src={`/img/photos_visio/photos_large/${hostesse.photo}.webp`} alt={hostesse.alt} fluid />
                </Col>
                <Col lg={6} className="p-0 d-flex flex-column justify-content-between">
                  <h1 className="mt-3 mt-lg-0 mb-3 p-1 p-md-2 p-lg-3 card-title-custom text-center">{hostesse.name}</h1>
                  <div className="px-1 px-lg-3 mb-2 text-center text-lg-start">
                    {hostesse.description}
                  </div>
                  <div className='text-center mb-5'>
                    {width < 769
                      ?
                      <>
                        <a href="tel:090404500" className="d-block">
                          <button className="button-home"><FontAwesomeIcon icon={faVideo} className='me-2' />Appel visio</button>
                        </a>
                        <span className="info-price"><small>Service 2,00 € / min + prix d'un appel</small></span>
                      </>
                      :
                      <div className="d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faVideo} className='fs-3 p-2 me-3 icon-video' />
                        <Image width={251} height={67} src={visioBelgique} className='sva' rounded alt="numéro d'appel en visio Belgique : 090404500" fluid />
                      </div>
                    }
                  </div>
                </Col>
                <Col className='text-center'>
                  <h2 className='card-title-custom p-2 mb-4 fs-3 fw-bold'>Appel en visio disponible depuis la Suisse</h2>
                  <Row className='mb-3'>
                    {width < 769
                      ?
                      <>
                        <Col xs={12} className='mb-4 text-center'>
                          <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                          <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faVideo} className='fs-3 p-2 me-3 icon-video' />
                            <a href="tel:0901989989">
                              <Image width={251} height={67} className='sva' src={visioSuisse} rounded alt="numéro d'appel en visio Suisse : 0901989989" fluid />
                            </a>
                          </div>
                        </Col>
                      </>
                      :
                      <>
                        <Col xs={12} className='mb-4 text-center'>
                          <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                          <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faVideo} className='fs-3 p-2 me-3 icon-video' />
                            <Image width={251} height={67} className='sva' src={visioSuisse} rounded alt="numéro d'appel en visio Suisse : 0901989989" fluid />
                          </div>
                        </Col>
                      </>
                    }
                  </Row>
                </Col>
              </Fragment>
            ))}
          </Row>
          {/* <div className="text-center mt-4 mb-5">
            <button onClick={() => navigate(-1)} className="button-home  text-center">Retour</button>
          </div> */}
        </Container>
        <Container className="my-3 text-center" fluid="lg">
          <Button id='button-loading' style={{ display: "block" }} className='loading-chat fw-bold mt-3' disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className='me-2'
            />
            Chargement du chat
          </Button>
          <div id="title-chat-container">
            <h5 id="title-chat" className='mb-2 p-3 fs-4 fw-bold rounded-1'>CHAT-VISIO</h5>
            <p>Conformément à la réglementation en Belgique, toutes les conversations audio-téléphoniques sur les numéros surtaxés sont limitées à 10 minutes. Passé ce délai, l'appel sera automatiquement interrompu. Vous devrez rappeler le <a className="fw-bold external-link" href="tel:090404500">0904 04 500</a> (2,00€/min) et demander à l’hôtesse de réactiver sa webcam.</p>
          </div>
          <div id='iframe-container'>
            <iframe title="chat-visio" id="iframe-chat" src="https://html5-chat.com/chat/50573/66d58b670ca26" allow="camera; fullscreen" width="100%" height="100%"></iframe>
          </div>
        </Container>
      </>
    )
  }
  return (
    <Navigate to="/404" />
  )
}

export default Profil;